<template>
  <div>
    <b-card class="shadow p-3">
      <h5>与{{ staff.name }}的聊天</h5>
      <b-row>
        <div style="height: 450px;overflow: auto">
          <div v-if="reloadFlag" v-for="c in communications" :key="c.id">
            <b-row v-if="c.originatorId+''===myInfo.id">
              <div style="text-align: center"><small>{{ c.gmtCreate }}</small></div>
              <b-col :md="10">
                <div class="p-1 px-3 m-1 chatBox " style="float:right;background-color: #95ec69">{{ c.content }}</div>
<!--                <small v-if="c.read">已读</small>-->
              </b-col>
              <b-col :md="2">
                <b-avatar class="bg-secondary"></b-avatar>
              </b-col>
            </b-row>
            <b-row v-else>
              <div style="text-align: center"><small>{{ c.gmtCreate }}</small></div>
              <b-col :md="2" style="text-align: right">
                <b-avatar class="bg-secondary"></b-avatar>
              </b-col>
              <b-col :md="10">
                <div class="p-1 px-3 m-1 border chatBox" style="float:left; ">{{ c.content }}</div>
<!--                <small v-if="c.read">已读</small>-->
              </b-col>
            </b-row>

          </div>
        </div>
      </b-row>
      <el-divider></el-divider>
      <b-row>
        <b-form-textarea
            class="w-75"
            style="height: 100px"
            id="textarea"
            v-model="form.content"
            placeholder="从此处开始输入..."
            rows="6"
            max-rows="12"
        ></b-form-textarea>
        <b-button class="w-25" variant="primary" @click="createCommunication">
          <i class="el-icon-s-promotion" style="font-size: 30px"></i>
        </b-button>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {addCommunicationReceiver, createCommunication, readCommunication, showChatBetweenTarget} from "@/api";

export default {
  name: 'CommunicationBox',
  props: {staff: Object},
  data() {
    return {
      myInfo: {},
      reloadFlag: true,
      communications: [],
      form: {
        content: ''
      }
    }
  },
  mounted() {
    this.showCommunications()
    this.myInfo = JSON.parse(localStorage.getItem('staffInfo'))

  },
  methods: {
    reloadComponent() {
      this.reloadFlag = false
      this.$nextTick(() => {
        this.reloadFlag = true
      })
    },
    createCommunication() {
      createCommunication('chat', 'chat', this.form.content).then(res => {
        if (res.data.status) {
          addCommunicationReceiver(res.data.data.id, this.staff.id).then(res1 => {
            if (res1.data.status) {
              this.showCommunications()
              this.$message.success(res.data.message)
              this.form.content = ''
            }
          })
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    showCommunications() {
      showChatBetweenTarget(this.staff.id).then(res => {
        if (res.data.status) {
          this.communications = res.data.data
          this.communications.forEach(c => {
            if (!c.read) {
              if (c.originatorId === this.staff.id || this.staff.id + '' === this.myInfo.id) {
                readCommunication(c.id)
              }
            }
          })
          this.reloadComponent()
        } else {
          this.$message.error(res.data.message)
        }
      })
    }
  }
}
</script>

<style scoped>
.chatBox {
  border-radius: 10px;
  display: inline-block;
  *display: inline;
  *zoom: 1;
}
</style>
