<template>
  <div>
    <b-container>
      <b-row class="m-3" style="text-align: center">
        <h3>{{ notice.title }}</h3>
      </b-row>
      <el-divider content-position="left">发布时间：{{ notice.publishTime }}</el-divider>
      <b-row>
        <p v-for="a in attachments" :key="a.id"><strong>附件：</strong><a :href="a.src">{{a.name}}</a></p>
        <p><strong>关键字：</strong> {{ notice.keyword }}</p>
        <p><strong>内容：</strong>{{ notice.content }}</p>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {readNotice, showNoticeAttachmentsByNoticeId, showNoticeById} from "@/api";

export default {
  name: "NoticePage",
  props: {noticeId: Number},
  data() {
    return {
      notice: {},
      attachments: []
    }
  },
  mounted() {
    this.getNotice()
    this.getAttachment()
    this.readNotice()
  },
  methods: {
    getNotice() {
      showNoticeById(this.noticeId).then(res => {
        if (res.data.status) {
          this.notice = res.data.data
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    getAttachment() {
      showNoticeAttachmentsByNoticeId(this.noticeId, 1, 50).then(res => {
        if (res.data.status) {
          this.attachments = res.data.data.content
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    readNotice(){
      readNotice(this.noticeId)
    }
  }
}
</script>

<style scoped>

</style>
