<template>
  <div>
    <b-container class="p-3">
      <h3>主页</h3>
      <p>欢迎来到亚信后台管理系统!</p>
      <b-row>
        <b-col :md="4">
          <!--          个人信息-->
          <b-card
              no-body
              style="max-width: 25rem;"
              img-src="https://placekitten.com/380/200"
              img-alt="Image"
              img-top
              class="shadow"
          >
            <template #header>
              <h4 class="m-1">基本信息</h4>
            </template>
            <b-card-body>
              <p><strong>姓名：</strong>{{ staff.name }}</p>
              <p><strong>工号：</strong>{{ staff.id }}</p>
            </b-card-body>

          </b-card>
          <!--          /个人信息-->
        </b-col>
        <b-col :md="8">
          <b-row>
            <b-card class="shadow m-3 mt-0" style="flex: 1">
              <UnreadCommunication :unread-only="false" :isHome="true" @add-tab="operateAdd"></UnreadCommunication>
            </b-card>
            <b-card class="shadow m-3 mt-0" style="flex: 1">
              <el-badge :value="200" :max="99" class="item">
                <h5>公告</h5>
              </el-badge>
              <b-table
                  :items="myNotices"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  stacked="md"
                  show-empty
                  small>

                <template #cell(content)="row">
                  <b-button size="sm" variant="outline-secondary" v-b-toggle.sidebar-right
                            @click="showNotice(row.item.id)">
                    简要查看
                  </b-button>
                </template>

              </b-table>
            </b-card>
          </b-row>
          <div>
            <b-sidebar width="500px" id="sidebar-right" title="公告" right shadow>
              <NoticePage v-if="showNoticeFlag" :notice-id="noticeId"></NoticePage>
            </b-sidebar>
          </div>
          <!--          简要日志-->
          <b-card class="shadow p-3">
            <h5>我的操作日志</h5>
            <el-timeline :reverse="reverse" class="m-3">
              <el-timeline-item
                  style="list-style: none"
                  v-for="(activity, index) in activities"
                  :key="index"
                  :timestamp="activity.timestamp">
                {{ activity.content }}
              </el-timeline-item>
            </el-timeline>
            <b-link>查看更多</b-link>
          </b-card>
          <!--          /简要日志-->
        </b-col>
      </b-row>
      <b-modal size="lg" hide-footer id="modal-1" title="快捷交流">
        <CommunicationBox v-if="cFlag" :staff="cStaff"></CommunicationBox>
      </b-modal>
    </b-container>
  </div>
</template>

<script>
import {showMyOperationLogs, showNoticesToMe} from "@/api";
import UnreadCommunication from "@/components/Communication/components/UnreadCommunication";
import NoticePage from "@/components/Notice/components/NoticePage";
import CommunicationBox from "@/components/Communication/components/CommnicationBox";

export default {
  name: "Welcome",
  components: {CommunicationBox, NoticePage, UnreadCommunication},
  data() {
    return {
      cFlag: false,
      cStaff: {},
      logPageSize: 3,
      myLogs: [],
      showNoticeFlag: false,
      noticeId: 0,
      staff: {},
      reverse: true,
      info: [{id: 10001}],
      activities: [],
      totalRows: 5,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 15, {value: 200, text: "Show a lot"}],
      filter: null,
      filterOn: [],
      myNotices: [],
      fields: [
        {key: 'id', label: '编号'},
        {key: 'title', label: '标题'},
        {key: 'content', label: '内容'}
      ]
    }
  },
  mounted() {
    this.staff = JSON.parse(localStorage.getItem('staffInfo'))
    this.getMyNotices()
    this.getMyLogs()
  },
  methods: {
    reloadComponent() {
      this.cFlag = false
      this.$nextTick(() => {
        this.cFlag = true
      })
    },
    operateAdd(val) {
      this.cStaff = val
      this.reloadComponent()
      this.$bvModal.show('modal-1')
    },
    getMyLogs() {
      showMyOperationLogs(1, 3).then(res => {
        if (res.data.status) {
          this.myLogs = res.data.data.content
          this.myLogs.forEach(l => {
            this.activities.push({content: l.operation, timestamp: l.operateTime})
          })
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    showNotice(id) {
      this.noticeId = id
      this.showNoticeFlag = true
    },
    getMyNotices() {
      showNoticesToMe(this.unreadOnly, 1, 50).then(res => {
        if (res.data.status) {
          this.myNotices = res.data.data.content
        } else {
          this.$message.error(res.data.message)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
