<template>
  <div>
    <el-badge :value="200" :max="99" class="item">
      <h5>消息</h5>
    </el-badge>
    <b-table :items="messages" :fields="fields"
             :per-page="5"
             :current-page="currentPage"
             striped responsive="sm">
      <template #cell(action)="row">
        <b-button size="sm" variant="outline-secondary" @click="checkMessage(row.item)">
          查看
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import {showReceivedCommunications} from "@/api";

export default {
  name: "UnreadCommunication",
  props: {
    unreadOnly: Boolean,
    isHome:Boolean
  },
  data() {
    return {
      rows: 50,
      currentPage: 1,
      searchId: '',
      messages: [],
      fields: [{key: 'originatorId', label: '工号'}, {key: 'originatorName', label: '姓名'}, {key: 'action', label: '操作'}],
    }
  },
  mounted() {
    this.getMessages()
  },
  methods: {
    getMessages() {
      showReceivedCommunications(this.unreadOnly, 1, 50).then(res => {
        if (res.data.status) {
          this.messages = res.data.data.content
        } else {
          this.$message.error(res.data.message)
        }
      })
    },
    checkMessage(item) {
      console.log(item)
      this.$emit('add-tab', item)
    }
  }
}
</script>

<style scoped>

</style>
